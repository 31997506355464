import Listing from '../components/Listing'


const Merchants = () => {
  return (
    <div>
        <Listing page="merchants"/>
    </div>
  )
}

export default Merchants