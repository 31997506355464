import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from  'react-loader-spinner';
import {IntCoupon} from '../Interfaces/IntCoupon'
import Header from '../components/Header'
import CardCoupon from '../components/CardCoupon'

const Category = () => {
    const [list, setList] = useState<IntCoupon[]>([])
    const params = useParams();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    
   
    const getCoupons = async () => {
        setLoading(true)
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/topdeals/' + params.id + '/coupon?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        const response = await fetch(apiurl)
        const data = await response.json()
        setList(data.deals)
        setLoading(false)
    }

    useEffect(() => {
        const name = localStorage.getItem('user');
        if(!name)
            navigate("/login");
        else{
            const user = JSON.parse(name);
            setUsername(user.username)
            getCoupons()
        }
        // eslint-disable-next-line
    }, [params.id, params.name])

  return (
    <>
    <Header page="" username={username}/>
    <div className='container-fluid' style={{position:'relative'}}>
        <RotatingLines
            strokeColor="rgb(13, 110, 253)"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={loading}
        />
        <div className='row'>
            <div className="col">
                <div className="categoryTitle">{params.name}</div>
            </div>
        </div>
        <div className="row">
        {
            list.map((item, i) => (
                <div className="col" key={i}>
                    <CardCoupon expireDate={item.expireDate} merchantPageStaffPick={item.merchantPageStaffPick} dealImageUrl={item.dealImageUrl} couponCode={item.couponCode} description={item.description} dealUrl={item.dealUrl} promotion={item.promotion} startDate={item.startDate} title={item.title} categories={item.categories} merchantName={item.merchantName}/>
                </div>
            ))
        }
        </div>
    </div>
    </>
  )
}

export default Category