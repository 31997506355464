import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Card from '../components/Card'
import {IntMerchant} from '../Interfaces/IntMerchant'
import { RotatingLines } from  'react-loader-spinner';
import { useParams} from "react-router-dom";


import Header from './Header';

export interface Props {
	page : string;
}

const Listing: React.FC<Props> = ({page}) => {
	const params = useParams();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [list, setList] = useState<IntMerchant[]>([])
    const [loading, setLoading] = useState<boolean>(false);
  
    const getMerchants = async (keyword?:string) => {
        setLoading(true)

	    let apiurl = (typeof keyword !== 'undefined') ? process.env.REACT_APP_BASE_URL + '/api/search/merchant/' + keyword + '?developer_key=' + process.env.REACT_APP_DEVELOPER_KEY : process.env.REACT_APP_BASE_URL + '/api/merchants?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY

		const response = await fetch(apiurl)
        const data = await response.json()
        setList(data.merchants)
        setLoading(false)
    }
 
    useEffect(() => {
        const name = localStorage.getItem('user');
        if(!name){
            navigate("/login");
        }else{
            const user = JSON.parse(name);
            setUsername(user.username)
            getMerchants(params.q)
        }
    }, [params.q])
 
    return (
        <>
		<Header page="merchants" username={username}/>
		
        <div className='container-fluid' style={{position:'relative'}}>
			<div className='row'>
				<div className="col">
					<div className="categoryTitle">MERCHANTS</div>
				</div>
			</div>
            <RotatingLines
                strokeColor="rgb(13, 110, 253)"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loading}
            />
            <div className="row">
            {
                list.map((item, i) => (
                    <div className="col" key={i}>
                        <Card merchantId={item.merchantId} dealCount={item.dealCount} description={item.description.substring(0, 150)} imageUrl={item.imageUrl} name={item.name} displayUrl={item.displayUrl}/>
                    </div>
                ))
            }
            </div>
        </div>
        </>
    )
}

export default Listing;