import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Header from "../components/Header"
import { useNavigate } from 'react-router-dom';
import {IntMerchant} from '../Interfaces/IntMerchant'
import { RotatingLines } from  'react-loader-spinner'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
var $  = require( 'jquery' )


const ManageTopMerchants = () => {
    const [list, setList] = useState<IntMerchant[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [username, setUsername] = useState('');
    const navigate = useNavigate();
    const activate = (e:any, merchantId:number) => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/topmerchant/add/' + merchantId + '?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        fetch(endpoint)
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                return false;
            }else{
                UpdateMerchants()
                return false;
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    const remove = (e:any, merchantId:number) => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/topmerchant/remove/' + merchantId + '?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        fetch(endpoint)
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                return false;
            }else{
                UpdateMerchants()
                return false;
            }
        })
        .catch(err => {
            console.log(err);
        });
    }


    const UpdateMerchants = async () => {
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/merchants?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        const response = await fetch(apiurl)
        const data = await response.json()
        setList(data.merchants)
    }


    const getMerchants = async () => {
        setLoading(true)
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/merchants?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        const response = await fetch(apiurl)
        const data = await response.json()
        setList(data.merchants)
        setLoading(false)

        const caller = setInterval(
            () => {
                if($('#file_export').length > 0 ){
                    if(!$.fn.dataTable.isDataTable("#file_export")){
                        $('#file_export').DataTable({
                            "dom": "lifrtp",
                            "order": [[ 0, "asc" ]]
                        }) 
                    }
                    clearInterval(caller)
                }
            },
            500
        )
    }

    
    useEffect(() => {
        const name = localStorage.getItem('user');
        if(!name)
            navigate("/login");
        else{
            const user = JSON.parse(name);
            setUsername(user.username)
            getMerchants()
            // eslint-disable-next-line
        }
    }, [])
    
    return (
    <>
        <Header page="manage-topmerchants" username={username}/>
        <div className='container-fluid' style={{position:'relative'}}>
            <div className='row'>
                <div className="col">
                    <div className="categoryTitle">Manage Top Merchants</div>
                </div>
            </div>
            <RotatingLines
                strokeColor="rgb(13, 110, 253)"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loading}
            />
            {
            loading ? '' :  
            <table id="file_export" className="table table-striped table-bordered display small">
            <thead>
                <tr>
                    <th>Logo</th>
                    <th>Name</th>
                    <th>Nb Coupons</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {
                list.map((item, i) => (
                    <tr key={i} >
                        <td>
                        <LazyLoadImage src={item.imageUrl} alt={item.name} style={{width:'120px'}} />
                        </td>
                        <td style={{ fontWeight:'bold'}}>{item.name}</td> 
                        <td><Link to={"/merchant/" + item.merchantId + "/coupons"} style={{ fontWeight:'bold', textDecoration:'none' }}>{item.dealCount}</Link></td>                      
                        <td>
                            {
                            item.topmerchant ? 
                            <button type="button" className="btn btn-danger">
                                <span onClick={(e) => remove(e, item.merchantId)}>Remove</span>
                            </button>
                            :
                            <button type="button" className="btn btn-success">
                                <span onClick={(e) => activate(e, item.merchantId)}>Add</span>
                            </button>
                            }
                        </td>
                    </tr>              
                ))    
            }
            </tbody>
            <tfoot>
                <tr>
                    <th>Logo</th>
                    <th>Name</th>
                    <th>Nb Coupons</th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
        }

            {/* <div className="row">
            {
                list.map((item, i) => (
                    <div className="col" key={i}>
                        <Card merchantId={item.merchantId} dealCount={item.dealCount} description={item.description.substring(0, 150)} imageUrl={item.imageUrl} name={item.name} displayUrl={item.displayUrl}/>
                    </div>
                ))
            }
            </div> */}
        </div>
    </>
  )
}

export default ManageTopMerchants