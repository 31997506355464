import React, {FC} from 'react';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Merchants from './Pages/Merchants'
import Categories from './Pages/Categories'
import Coupons from './Pages/Coupons'
import Category from './Pages/Category'
import TopDeals from './Pages/TopDeals'
import TopMerchants from './Pages/TopMerchants'
import ManageTopMerchants from './Pages/ManageTopMerchants'
import SearchByDomain from './Pages/SearchByDomain'
import Login from './Pages/Login'

interface Props{
}

const App:FC<Props> = () => {
  return (
	<Router>
		<Routes>
			<Route path = "/" element={<Merchants/>} />
			<Route path = "/categories" element={<Categories/>} />
			<Route path = "/merchant/:id/coupons" element={<Coupons />} />
			<Route path = "/merchants/:q" element={<Merchants />} />
			<Route path = "/merchants" element={<Merchants />} />
			<Route path = "/category/:id/:name" element={<Category />} />
			<Route path = "/topmerchants" element={<TopMerchants />} />
			<Route path = "/topdeals" element={<TopDeals />} />
			<Route path = "/login" element={<Login />} />
			<Route path = "/search-by-domain" element={<SearchByDomain />} />
			<Route path = "/manage-topmerchants" element={<ManageTopMerchants />} />
		</Routes> 
	</Router>

  );
}

export default App;
