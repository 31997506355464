import React, {useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdContentCopy } from "react-icons/md";
import { IntCoupon } from '../Interfaces/IntCoupon'



const CardCoupon:React.FC<IntCoupon> = ({couponCode, description, dealUrl, promotion, startDate, title, categories, merchantName, dealImageUrl, merchantPageStaffPick, expireDate}) => {

    const [show, setShow] = useState(false)

    const copyCode = (e: React.FormEvent, code:string) => {
        navigator.clipboard.writeText(code);
        toast("Coupon Code is copied!")
    }
    const showCoupon = (e: React.FormEvent) => {
        setShow(true)
    }
    
    return (
        <div className="coupon">
            <ToastContainer />
            <div className="merchantName">
                <img src={dealImageUrl} alt={merchantName} style={{ width:'120px' }} />{merchantName}
                {
                merchantPageStaffPick ? 
                <div className="topdeal">TOP DEAL</div>
                : ''
                }
            </div>
            <div className="content">
                {
                promotion 
                ? 
                <div className="left">
                    <div className="promotion">{promotion}</div>
                    {
                    couponCode 
                    ? 
                        <div className="couponCode-container">
                            {
                            !show ? 
                            <button type="button" className="couponcode-hint" onClick={(e) => showCoupon(e)}>COUPON CODE</button>
                            : ''
                            }
                            {
                            show ? 
                                <>
                                    <button type="button" className="couponcode-hint">{couponCode}</button>
                                    <button className="copy" onClick={(e) => copyCode(e, couponCode)}><MdContentCopy color="green"/></button>
                                </>
                            : ''
                            }
                            
                        </div>
                    :
                    ''
                    }
                </div>
                : ''
                }
                <div className="right">
                    <div className="title">{title}</div>
                    <div className="description">{description}</div>
                    <div className="startDate">Start date : {startDate}</div>
                    {
                    expireDate ? 
                    <div className="startDate">Expire date : {expireDate}</div>
                    : ''
                    }       
                    <div className="getCoupon"><a href={dealUrl}>Get Coupon</a></div>
                </div>
            </div>
        </div>
    )
}

export default CardCoupon