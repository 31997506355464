import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import { RotatingLines } from  'react-loader-spinner';
import {IntCoupon} from '../Interfaces/IntCoupon'
import Header from '../components/Header'
import CardCoupon from '../components/CardCoupon'

const Coupons = () => {
    const [list, setList] = useState<IntCoupon[]>([])
    const params = useParams();
    const [merchantImage, setMerchantImage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);
    
   
    const getCoupons = async () => {
        setLoading(true)
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/merchant/id/' + params.id + '/coupons?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        const response = await fetch(apiurl)
        const data = await response.json()
        setMerchantImage(data.deals[0].dealImageUrl);
        setList(data.deals)
        setLoading(false)
    }

    useEffect(() => {
        getCoupons()
        // eslint-disable-next-line
    }, [])

  return (
    <>
    <Header page="coupons"/>
    <div className='container-fluid' style={{position:'relative'}}>
        <RotatingLines
            strokeColor="rgb(13, 110, 253)"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={loading}
        />
        <div className='row'>
            <div className="col-xs-6 col-xs-offset-3">
                <img src={merchantImage} alt={merchantImage} style={{maxWidth:'200px', margin: '20px 0'}}/>
            </div>
        </div>
        <div className="row">
        {
            list.map((item, i) => (
                <div className="col" key={i}>
                    <CardCoupon expireDate={item.expireDate} merchantPageStaffPick={item.merchantPageStaffPick} dealImageUrl={item.dealImageUrl} couponCode={item.couponCode} description={item.description} dealUrl={item.dealUrl} promotion={item.promotion} startDate={item.startDate} title={item.title} />
                </div>
            ))
        }
        </div>
    </div>
    </>
  )
}

export default Coupons