import React, {useState, useEffect} from 'react'
import { RotatingLines } from  'react-loader-spinner';
import {IntCoupon} from '../Interfaces/IntCoupon'
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header'
import CardCoupon from '../components/CardCoupon'
import { IntHolidayCat } from '../Interfaces/IntHolidayCat';


const TopDeals = () => {
    const [list, setList] = useState<IntCoupon[]>([])
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [listCategories, setListCategories] = useState<IntHolidayCat[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [category, setCategory] = useState<any>(20743);
    
   
    const getCoupons = async (category:any) => {
        setLoading(true)
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/topdeals/' + category + '/coupon?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        const response = await fetch(apiurl)
        const data = await response.json()
        setList(data.deals)
        setLoading(false)
    }

    const getListCategories = async () => {
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/category/21027/subcategories?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        const response = await fetch(apiurl)
        const data = await response.json()
        setListCategories(data.categories)
    }

    const handleCategory = (e:React.ChangeEvent<HTMLSelectElement>) => {
        setCategory(e.target.value)
        setTimeout(() => {
            getCoupons(e.target.value)
        }, 300);
        
    }
    
    useEffect(() => {
        const name = localStorage.getItem('user');
        if(!name)
            navigate("/login");
        else{
            const user = JSON.parse(name);
            setUsername(user.username)
            getListCategories()
        }
    }, [])

    useEffect(() => {
        getCoupons(category)
    }, [category])


  return (
    <>
    <Header page="topdeals" username={username}/>
    <div className='container-fluid' style={{position:'relative'}}>
       
        <div className='row'>
            <div className="col">
                <div className="categoryTitle">TOP DEALS</div>
            </div>
        </div>
        <div className='row'>
            <form>
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-3">
                            <label style={{fontWeight:'bold', fontSize: '20px', margin:'10px 0'}}>Holidays & Seasonal</label>
                            <div className="controls">
                                <select
                                    name="category"
                                    id="category"
                                    className="form-select mb-3" 
                                    onChange={handleCategory} 
                                    value={category}>
                                        <option value="">Select a category</option>
                                        {
                                            listCategories.map((item, i) => (
                                                item.id === 20743 ?
                                                    <option value={item.id} key={item.id} selected>
                                                            {item.name}
                                                    </option>
                                                :
                                                    <option value={item.id} key={item.id}>
                                                            {item.name}
                                                    </option>

                                            ))
                                        }
                                </select>
                                <div className="invalid-feedback">The breed field is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <RotatingLines
            strokeColor="rgb(13, 110, 253)"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={loading}
        />
        <div className="row">
        {
            list.map((item, i) => (
                <div className="col" key={i}>
                    <CardCoupon expireDate={item.expireDate} dealImageUrl={item.dealImageUrl} couponCode={item.couponCode} description={item.description} dealUrl={item.dealUrl} promotion={item.promotion} startDate={item.startDate} title={item.title} categories={item.categories} merchantName={item.merchantName} merchantPageStaffPick={item.merchantPageStaffPick}/>
                </div>
            ))
        }
        </div>
    </div>
    </>
  )
}

export default TopDeals;