import { useState, useEffect } from 'react'
import CardCategory from '../components/CardCategory'
import {IntCategory} from '../Interfaces/IntCategory'
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from  'react-loader-spinner';
import Header from '../components/Header'

export interface Props {
	page : string;
}

const Categories = () => {

    const [list, setList] = useState<IntCategory[]>([])    
	const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const getCategories = async () => {
        setLoading(true)
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/categories?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        const response = await fetch(apiurl)
        const data = await response.json()
        setList(data.results)
        setLoading(false)
    }
 
    useEffect(() => {
        const name = localStorage.getItem('user');
        if(!name)
            navigate("/login");
        else{
            const user = JSON.parse(name);
            setUsername(user.username)
            getCategories()
        }
    }, [])
 
    return (
        <>
        <Header page="categories" username={username}/>
        <div className='container-fluid' style={{position:'relative'}}>
            <div className="col">
                <div className="categoryTitle">CATEGORIES</div>
            </div>
            <RotatingLines
                strokeColor="rgb(13, 110, 253)"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loading}
            />
            <div className="row">
            {
                list.map((item, i) => (
                    <div className="col" key={i}>
                        <CardCategory name={item.name} categoryId={item.categoryId} childrenCategories={item.childrenCategories}/>
                    </div>
                ))
            }
            </div>
        </div>
        </>
    )
}

export default Categories;