import React from 'react'
import { IntCategory } from '../Interfaces/IntCategory'
import { IntChildrenCategory } from '../Interfaces/IntChildrenCategory';
import { Link} from "react-router-dom";


const CardCategory:React.FC<IntCategory> = ({categoryId, name, childrenCategories}) => {
  return (
    <div className="card">
        <div className="card-body">
            <h5 className="card-title small"><Link to={"/category/" + categoryId + "/" + name}>{name}</Link></h5>
            <ul>
              {
                childrenCategories.map((item:IntChildrenCategory) => (
                  <li key={item.id} className="card-title verysmall"><Link to={"/category/" + item.id + "/" + item.name}>{item.name}</Link></li>
                ))
              }
            </ul>
          </div>
    </div>
  )
}

export default CardCategory