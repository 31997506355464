import React from 'react'
import { IntMerchant } from '../Interfaces/IntMerchant'
import { Link} from "react-router-dom";


const Card:React.FC<IntMerchant> = ({merchantId, dealCount, description, displayUrl, imageUrl, name}) => {
  return (
    <div className="card">
        <img className="card-img-top" src={imageUrl} alt={name} />
          <div className="card-body" style={{ background:'#cccccc2e' }}>
            <div className="card-title">
              {name}
            </div>
            <p className="card-text" style={{ fontSize:'14px', fontWeight:'500' }}><Link to={"/merchant/" + merchantId + '/coupons'} style={{ textDecoration:'none', fontSize:'14px' }}>{dealCount} Coupons</Link></p>
            {/* <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
              {displayUrl}
            </div> */}
        </div>
    </div>
  )
}

export default Card