import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {IntCoupon} from '../Interfaces/IntCoupon'
import Header from '../components/Header'
import { RotatingLines } from  'react-loader-spinner';
import CardCoupon from '../components/CardCoupon'

const SearchByDomain = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [list, setList] = useState<IntCoupon[]>([])
    const [domain, setDomain] = useState<string>('');
    const [nodeal, setNoDeal] = useState<boolean>(false)
    const [merchantImage, setMerchantImage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);

    const handleSearch = (e:React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
		setDomain(e.target.value)
	}

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        setLoading(true)
        const apiurl = process.env.REACT_APP_BASE_URL + '/api/merchant/url/' + encodeURIComponent(domain.replace(/^https?:\/\//, '')) + '/coupons?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY
        const response = await fetch(apiurl)
        const data = await response.json()
        if(data.action === 'success'){
            setMerchantImage(data.deals[0].dealImageUrl);
            setList(data.deals)
            setNoDeal(false)
        }else{
            setNoDeal(true)
        }
        setLoading(false)
    }

    useEffect(() => {
        const name = localStorage.getItem('user');
        if(!name)
            navigate("/login");
        else{
            const user = JSON.parse(name);
            setUsername(user.username)
        }
    }, [])


    return (
    <>
        <Header page="searchbydomain" username={username}/>
        <div className='container-fluid' style={{position:'relative'}}>
            <div className='row'>
                <div className="col">
                    <div className="categoryTitle">Search By Domain</div>
                </div>
            </div>
            <div className='row'>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3">
                                <input type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" value={domain} onChange={handleSearch}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <RotatingLines
                strokeColor="rgb(13, 110, 253)"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loading}
            />
            <div className='row'>
                <div className="col-xs-6 col-xs-offset-3">
                    <img src={merchantImage} alt={merchantImage} style={{maxWidth:'200px', margin: '20px 0'}}/>
                </div>
            </div>
            {nodeal ? 
            <div>No result found for the domain <b>{domain}</b></div>
            :
                <div className="row">
                {
                    list.map((item, i) => (
                        <div className="col" key={i}>
                            <CardCoupon expireDate={item.expireDate} merchantPageStaffPick={item.merchantPageStaffPick} dealImageUrl={item.dealImageUrl} couponCode={item.couponCode} description={item.description} dealUrl={item.dealUrl} promotion={item.promotion} startDate={item.startDate} title={item.title} />
                        </div>
                    ))
                }
                </div>
            }
        </div>
    </>
  )
}

export default SearchByDomain