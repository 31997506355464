import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const showError = {
        display : (errorMessage !== '') ? "block" : "none",
        padding : '8px',
        margin : '10px',
        borderRadius : '5px'
    }
    
    const getLogin = async () => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/login?developerKey=' + process.env.REACT_APP_DEVELOPER_KEY;
        setLoading(true)
      
        var formBody = 'username=' + username + '&password=' + password;
        
        await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          },
          body: formBody
        })

        .then(response => response.json())
        .then(response => { 
            if(response.action === 'failed'){
                setErrorMessage("Invalid username / password")
                setLoading(false)
            }else{
                const user = {
                    username : response.username,
                    token: response.token
                }
                setLoading(false);
                localStorage.setItem("user", JSON.stringify(user)); 
                window.location.replace("/");
            }   
        })
        .catch(err => {
            setLoading(false)
            console.log(err);
        });
    }

    const handleSubmit = (e : FormEvent) => {
        e.preventDefault();
        getLogin();
    }

    const box = {
        border:'1px solid #ccc', 
        borderRadius:'10px', 
        padding:'20px', 
        margin:'20px',
    }

    useEffect(() => {
        const name = localStorage.getItem('user');
        if(name)
            navigate("/");
        // eslint-disable-next-line
    }, [])

    return (
        <div className="container-login" style={{ maxWidth:'500px' }}>
            <div style={box}>
                <form>
                    <h3>Sign In</h3>
                    <div className="row" style={{ fontSize:'12px' }}>
                        <div className="
                                col
                                bg-danger
                                text-white
                                border-0
                                fade
                                show
                                " 
                                role="alert"
                                style={showError}
                                >
                                <strong>Error - </strong>{errorMessage}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Username</label>
                        <input type="text" className="form-control" placeholder="Enter username" onChange={(e) => setUsername(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <br/>
                    {
                    loading ? <div className="loading">Loading...</div>
                    :
                    <button type="submit" style={{width:'100%'}} className="btn btn-primary btn-block" onClick={handleSubmit}>
                    Submit
                    </button>
                    }
                </form>
            </div>
        </div>
    );
}

export default Login;
