import * as React from 'react';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Link} from "react-router-dom";
import { GrSearch } from "react-icons/gr";
import { useParams} from "react-router-dom";

export interface Props {
	page : string;
	username? : string;
}

const Header: React.FC<Props> = ({page, username}) => {
	const params = useParams();
	const navigate = useNavigate();
	const [keyword, setKeyword] = useState<any>(params.q);
	const handleSearch = (e:React.ChangeEvent<HTMLInputElement>) => {
		setKeyword(e.target.value)
	}

	const logout = () => {
        localStorage.removeItem('user');
		navigate("/login");
    }

	useEffect(() => {
	}, []);
  

  return (
    <nav className="navbar navbar-expand-lg navbar-dark " style={{ background:'#0d6efd', height:'80px'}}>
      	<div className="container-fluid">
			<Link className="navbar-brand" to="/"><h5>Home</h5></Link>
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse justify-content-between" id="navbarNavDropdown">
				<ul className="navbar-nav">
					
					<li className="nav-item">
					{
						page === 'merchants' ? 
						<Link className="nav-link active" to="/">Merchants</Link>
					:
						<Link className="nav-link" to="/">Merchants</Link>
					}
					</li>	
					<li className="nav-item">
					{
						page === 'categories' ? 
						<Link className="nav-link active" to="/categories">Categories</Link>
					:
						<Link className="nav-link" to="/categories">Categories</Link>
					}
					</li>
					<li className="nav-item">
					{
						page === 'topmerchants' ? 
						<Link className="nav-link active" to="/topmerchants">Top Merchants</Link>
					:
						<Link className="nav-link" to="/topmerchants">Top Merchants</Link>
					}
					</li>
					<li className="nav-item">
					{
						page === 'topdeals' ? 
						<Link className="nav-link active" to="/topdeals">Top Deals</Link>
					:
						<Link className="nav-link" to="/topdeals">Top Deals</Link>
					}
					</li>
					<li className="nav-item">
					{
						page === 'manage-topmerchants' ? 
						<Link className="nav-link active" to="/manage-topmerchants">Manage Top Merchants</Link>
					:
						<Link className="nav-link" to="/manage-topmerchants">Manage Top Merchants</Link>
					}
					</li>
					<li className="nav-item">
					{
						page === 'searchbydomain' ? 
						<Link className="nav-link active" to="/search-by-domain">Search by domain</Link>
					:
						<Link className="nav-link" to="/search-by-domain">Search by domain</Link>
					}
					</li>
					{username !== '' ? 
						<li className="nav-item">
						{
							<Link className="nav-link" to="/#" onClick={logout} style={{ marginLeft:'20px', color:'#000', fontWeight:'500' }}>Log Out</Link>
						}
						</li>
					: ''
					}	
					
				</ul>
				
				<div className="navbar-nav mr-auto mt-4 mt-lg-0">
					<form action={"/merchants/" + keyword} method="GET">
						<div className="input-group">
							<div className="form-outline">
							<input className="form-control mr-sm-2" type="search" placeholder="Search Merchant" aria-label="Search" id="searchkeyword" value={keyword} onChange={handleSearch}/>
							</div>
							<button id="search-button" type="submit" className="btn btn-primary">
								<i className="fas fa-search"><GrSearch /></i>
							</button>
						</div>
					</form>
				</div>
				{/* <div className="collapse navbar-collapse" id="navbar-list-4">
					<ul className="navbar-nav">
						<li className="nav-item dropdown">
						<a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<img src="/user.png" width="40" height="40" className="rounded-circle"/>
						<span style={{fontSize:'12px',color:'#fff',margin:'0 5px'}} className="d-none d-sm-block ms-1">{username}</span>
						</a>
						<div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ right:'0px' }}>
						<a className="dropdown-item" href="/#" onClick={logout} style={{ fontSize:'12px' }}>Log Out</a>
						</div>
					</li>   
					</ul>
				</div> */}
				
			</div>
      	</div>
	  </nav>
  );
}

export default Header;
